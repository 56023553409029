import WhatshotIcon from '@material-ui/icons/Whatshot';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import MovieIcon from '@material-ui/icons/Movie';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PetsIcon from '@material-ui/icons/Pets';

export const homeNames = [
  "trending", "recent"
]

export const sectNames = [
  "funny", "action", "animal", "sports", "movies",
]

const sectStyle = {height: '22px', width: '22px', margin: 'auto'}
const sectLargeStyle = {height: '26px', width: '26px', margin: 'auto'}

const homeStyle = {fontSize: "1.6em", color: 'black'}
const sectIconStyle = {
  height: '30px',
  width: '30px',
  borderRadius: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
}

const iconColor = "rgba(255, 255, 255, 0.8)"

export const sectIcons = [
  <div style={{...sectIconStyle, backgroundColor: '#f7ab05', color: iconColor}}>
    <EmojiEmotionsIcon style={sectStyle}/>
  </div>,
  <div style={{...sectIconStyle, backgroundColor: '#b31e6f', color: iconColor}}>
    <MotorcycleIcon style={sectLargeStyle}/>
  </div>,
  <div style={{...sectIconStyle, backgroundColor: '#7cb6a1', color: iconColor}}>
    <PetsIcon style={sectStyle}/>
  </div>,
  <div style={{...sectIconStyle, backgroundColor: '#7c73e6', color: iconColor}}>
    <SportsBaseballIcon style={sectStyle}/>
  </div>,
  <div style={{...sectIconStyle, backgroundColor: '#3a435a', color: iconColor}}>
    <MovieIcon style={{sectStyle}}/>
  </div>,
]

export const homeIcons = [
  <WhatshotIcon style={homeStyle}/>,
  <ScheduleIcon style={homeStyle}/>, 
]

export const homePaths = ["/", "/trending", "/recent", "/funny", "/action", "/animal", "/sports", "/movies"];

export const indexList = {
  "": 0,
  "trending": 0,
  "recent": 1,
  "funny": 2,
  "action": 3,
  "animal": 4,
  "sports": 5,
  "movies": 6,
}
