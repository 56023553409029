import React from 'react';
import { Autocomplete } from './Autocomplete';
import { useHistory } from "react-router-dom";

function SearchBar() {
  const history = useHistory();
  
  const submitQuery = (val) => {
    console.log(val)
    window.scrollTo(0, 0);
    //searchbar removes body overflow-y css, need to add it back in
    document.querySelector('body').classList.remove('aa-Detached');
    if (val){
      history.push(`/search?q=${val}`);
    }
  }

  const onSubmit = (event) => {
    submitQuery(event.state.query)
  }

  const onSelect = (event) => {
    submitQuery(event.itemInputValue)
  }

  return (
    <div className="searchbox" id="aa-Form">
      <Autocomplete
        openOnFocus={true}
        placeholder="Search"
        onSubmit={onSubmit}
        onSelect={onSelect}
      />
    </div>
  );
}

export default SearchBar;
