import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { ListSubheader, makeStyles } from '@material-ui/core';
import { Link, useLocation } from "react-router-dom";
import { indexList, sectNames, sectIcons, homeNames, homeIcons } from "../adapters/sections"

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#36454F",
    // backgroundColor: 'red',
    minWidth: 50,
  },
  listItem: {
    borderRadius: 6
  },
  largeItemText: {
    // color: "#1878f2",
    fontSize: '1.1em',
    // fontWeight: 550
  },
  selected: {
    backgroundColor: '#ecf4ff'
  },
  largeIcon: {
    // color: "#1878f2",
    color: "#36454F",
    minWidth: 50,
    // fontSize: "1.8em"
  },
  sidePanel: {
    height: '100%',
    // width: '200px',
    // marginRight: '40px',
  },
  backPanel: {
    height: '100%',
    // width: '24%',
    marginTop: '10px',
    margin: '20px',
  },
  arrowButton: {
    backgroundColor: '#eeeeee',
    '&:hover': {
      backgroundColor: '#e1e1e1'
    }
  }, 
  sectIcon: {
    backgroundColor: 'orange',
    color: 'white',
    height: '30px',
    width: '30px',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  }
}))


function NavBar() {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const location = useLocation();

  useEffect(() => {
    setSelectedIndex(indexList[location.pathname.substr(1,)])
  }, [location])

  return (
    <div>
      <List component="nav" aria-label="categories">
      {homeNames.map((x, index) => {
        return (
          <Link style={{ textDecoration: 'none', color: 'black' }} to={`/${x}`} key={index}>
          <ListItem 
            className={`${classes.listItem}`}
            button
            selected={selectedIndex === index}
            >
              <ListItemIcon 
                className={classes.largeIcon}
              >
                {homeIcons[index]}
              </ListItemIcon>
              <ListItemText 
                classes={{primary: classes.largeItemText}}
                primary={x.charAt(0).toUpperCase() + x.slice(1)}
              /> 
            </ListItem>
            </Link>      
        )
      })}
      </List>
      <Divider />
      <List 
        component="nav" 
        aria-label="categories"
        style={{paddingTop: 0}}
      >
      <ListSubheader disableSticky={true} style={{height: 40, fontWeight: 600}}>
        {"Categories"}
      </ListSubheader>
      {sectNames.map((x, index) => {
        return (
          <Link style={{ textDecoration: 'none', color: 'black' }} to={`/${x}`} key={index}>
          <ListItem 
            className={classes.listItem}
            button
            selected={selectedIndex === index + homeNames.length}
            >
              <ListItemIcon className={classes.icon}>
                {/* <div className={classes.sectIcon}> */}
                {sectIcons[index]}
                {/* </div> */}
              </ListItemIcon>
              <ListItemText 
                primary={x.charAt(0).toUpperCase() + x.slice(1)}
              /> 
            </ListItem>
            </Link>      
        )
      })}
      </List>
    </div>
  )
}

export default NavBar
